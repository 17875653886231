import React, { useEffect, useState } from 'react';
import AppMessageView from '../AppMessageView';
import AppLoader from '../AppLoader';
// import { useDispatch, useSelector } from 'react-redux';
// import { hideMessage } from "@crema/redux-toolkit/actions";
import {
  useInfoViewActionsContext,
  useInfoViewContext,
} from '@crema/context/InfoViewContextProvider';
import { v4 as uuidv4 } from 'uuid';

const AppInfoView = () => {
  // Only for Context APIs
  const { error, loading, message, warning } = useInfoViewContext();
  const { clearInfoView } = useInfoViewActionsContext();
  // const [displayMessage, setDisplayMessage] = useState<{
  //   message: '';
  //   error: '';
  //   warning: '';
  // }>();

  // useEffect(() => {
  //   const hasAnyMessage = message || error || warning;
  //   console.log('AppInfoView useEffect changes:', {
  //     hasAnyMessage,
  //     message,
  //     error,
  //     warning,
  //   });
  //
  //   if (hasAnyMessage) {
  //     // setDisplayMessage({
  //     //   message,
  //     //   error,
  //     //   warning,
  //     // });
  //     // need to create local message
  //   }
  // }, [message, error, warning]);

  // useEffect(() => {
  //   const hasAnyMessage = message || error || warning;
  //   console.log('AppInfoView useEffect changes message:', {
  //     hasAnyMessage,
  //     message,
  //     error,
  //     warning,
  //   });
  //
  //   if (hasAnyMessage) {
  //     // setDisplayMessage({
  //     //   message,
  //     //   error,
  //     //   warning,
  //     // });
  //     // need to create local message
  //   }
  // }, [message]);

  // // Only for redux
  // const { error, loading, message } = useSelector(({ common }) => common);
  // const dispatch = useDispatch();
  // const clearInfoView = () => {
  //   dispatch(hideMessage());
  // };

  const showMessage = () => {
    return (
      <AppMessageView
        variant="success"
        message={message.toString()}
        clearInfoView={clearInfoView}
        messageId={uuidv4()}
      />
    );
  };

  const showError = () => {
    return (
      <AppMessageView
        variant="error"
        message={error.toString()}
        clearInfoView={clearInfoView}
        messageId={uuidv4()}
      />
    );
  };

  const showWarning = () => {
    return (
      <AppMessageView
        variant="warning"
        message={React.isValidElement(warning) ? warning : warning.toString()}
        clearInfoView={clearInfoView}
        messageId={uuidv4()}
      />
    );
  };

  return (
    <>
      {loading ? <AppLoader /> : null}
      {message ? showMessage() : null}
      {error ? showError() : null}
      {warning ? showWarning() : null}
    </>
  );
};

export default AppInfoView;
